<template>
  <div class="page-redirect">Estamos redirecionando você, aguarde...</div>
</template>

<script>
import { meMixin } from '@/mixins/me';
import { showMessage } from '@/helpers/messages';
import { setToken } from '@/helpers/token';
import { TokenService } from '@/services/api/token';

export default {
  mixins: [meMixin],

  data: () => ({
    model: {
      token: null,
      clientId: null,
      clientSecret: null
    }
  }),

  created() {
    const { token, clientId, clientSecret } = this.$route.query;

    if (!token || !clientId || !clientSecret) {
      showMessage('error', 'Parâmetros inválidos, verifique!');
      return;
    }

    this.model = {
      token,
      clientId,
      clientSecret
    };

    this.execute();
  },

  methods: {
    async execute() {
      try {
        const tokenService = new TokenService();
        const { status, data } = await tokenService.partner(this.model);

        if (status === 200) {
          setToken(data.token);
          this.$store.commit('authentication/setToken', data.token);
          this.getMe();
          this.$router.push({ name: 'dashboard' });
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-redirect {
  height: 100vh;
  padding: 2rem;
  background: var(--v-primary-base);
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
}
</style>
